import React from "react"
import CloseIcon from "../Icons/CloseIcon"
import {
  Modal,
  ModalTitle,
  CloseButton,
  ModalDialog,
  ModalContent,
  ModalBody,
  ModalBackdrop,
} from "../Modal"
import QuickContactForm from "../QuickContactForm"

class QuotePopup extends React.Component {

  render() {
    const { isVisible, location, formName } = this.props
    return (
      <>
      <Modal
        className={"modal fade " + (isVisible ? "show" : "")}
        style={{ display: isVisible ? "block" : "none" }}
      >
        <ModalDialog className="modal-dialog modal-xl modal-dialog-centered">
          <ModalContent className="modal-content">
            <ModalBody className="modal-body">
              <CloseButton className="close" onClick={this.props.onClose}><CloseIcon /></CloseButton>
              <ModalTitle className="modal-title">Get Started Today With Your Free Quote</ModalTitle>
              <QuickContactForm location={location} formName={formName ? formName : "Quick Contact Form"} />
            </ModalBody>
          </ModalContent>
        </ModalDialog>
      </Modal>
      {isVisible && (
        <ModalBackdrop className={"fade " + (isVisible ? "show" : "")} />
      )}
      </>
    )
  }
}

export default QuotePopup
