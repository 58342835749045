import React from 'react'
import styled from 'styled-components'
import { BaseButton } from '../Section'

const WhiteBtn = styled(BaseButton)`	
	color:#062C44;
    background:#fff;	    
	border:#fff;
	&:before, &:after {
        display:none;
    }
	& .icon{
		> svg{
			fill:#062C44;
		}
	}
	
	&:hover{
		color:#fff;	
	  	background:#062C44;	    
          & .icon{
            > svg{
                fill:#fff;
            }
        }
	}
`
const WhiteButton = (props) => {
	const { icon, iconAfter, text, size } = props;
	return(
		<WhiteBtn className={`btn ${size ? `btn-${size}` : ''}`}>
			{ iconAfter ? (
				<>
					{ text && <span className='text'>{text}</span>}
					{ iconAfter && <span className='icon'>{iconAfter}</span>}
				</>
				):(
					<>
						{ icon && <span className='icon'>{icon}</span>}
						{ text && <span className='text'>{text}</span>}
					</>
				)
			}
		</WhiteBtn>
	)
}


export default WhiteButton
